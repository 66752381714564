
.base {
    background: inherit !important;
}

#gray-overlay {
    position: fixed; /* 固定定位，確保覆蓋層始終滿屏 */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(12, 12, 12, 0.1); /* 半透明的灰色 */
    pointer-events: none; /* 確保不會攔截鼠標事件 */
    mix-blend-mode: overlay; /* 混合模式 */
    z-index: -1; /* 確保它在其他內容之下 */
}

#gray-overlay::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../assets/landing_bg.png') center/cover no-repeat;
    pointer-events: none;
    mix-blend-mode: overlay;
    z-index: -1;
}